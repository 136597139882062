import { useEffect, useState, Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import { ApiService } from "./components/API-host/apiHost";

const Hero = lazy(() => import("./components/Header/Hero-section"));
const ClientSection = lazy(() => import("./pages/Clients"));
const MissionsSection = lazy(() => import("./pages/Missions-Section"));
const TestimonialSection = lazy(() => import("./pages/Testimonial-section"));
const Footer = lazy(() => import("./components/Footer"));
const Header = lazy(() => import("./components/Header"));
// const Careers = lazy(() => import("./pages/Careers"));
const UsaCareers = lazy(() => import("./pages/Careers/UsaCareers"));
const IndiaCareers = lazy(() => import("./pages/Careers/IndiaCareers"));
const Service = lazy(() => import("./pages/Services"));
const Contact = lazy(() => import("./pages/Contact-us"));
const Page404 = lazy(() => import("./pages/Error"));
const ScrollToTopButton = lazy(() => import("./components/ScrollToTopButton"));
const SoftwareDevelopment = lazy(() =>
   import("./pages/Services/Service/SoftwareDevelopment")
);
const SoftwareTesting = lazy(() =>
   import("./pages/Services/Service/SoftwareTesting")
);
const SalesManagement = lazy(() =>
   import("./pages/Services/Service/Salesmanagement")
);
const CustomerSupport = lazy(() =>
   import("./pages/Services/Service/CustomerSuppport")
);
const BigData = lazy(() => import("./pages/Services/Service/BigData"));
const CloudServiceSection = lazy(() =>
   import("./pages/Services/Service/CloudService")
);
const Department = lazy(() => import("./pages/Careers/Departments/Department"));
const JobDescription = lazy(() =>
   import("./pages/Careers/JobDescription/JobDescription")
);

const App = () => {
   const [dev] = useState(`
  /**
   *  
     BUILT WITH ❤ BY ENFEC'S REACT DEV'S 😎 
   *
   **/
  `);
   const [reviews, setReviews] = useState([]);

   useEffect(() => {
      window.scrollTo(0, 0);
      console.log(dev);
   }, [dev]);

   useEffect(async () => {
      try {
         let api = await fetch(
            `${ApiService.hostURL}${ApiService.devServer}/ratings`
         );

         if (api.status != 200) throw new Error("Something went wrong!");
         let response = await api.json();
         setReviews(response);
      } catch (error) {
         console.log(error.message);
      }
   }, [dev]);

   const fallbackUiStyles = {
      textAlign: "center",
      fontSize: "4rem",
      fontWeight: 700,
   };

   return (
      <>
         <Router>
            <Suspense
               fallback={<div style={fallbackUiStyles}>Loading....</div>}
            >
               <Header />
               <ScrollToTopButton />
               <Switch>
                  <Route exact path="/">
                     <Hero />
                     <MissionsSection />
                     <TestimonialSection reviews={reviews} />
                     <ClientSection />
                  </Route>
                  {/* <Route exact path="/careers" component={Careers} /> */}
                  <Route exact path="/careers/usa" component={UsaCareers} />
                  <Route exact path="/careers/india" component={IndiaCareers} />
                  <Route exact path="/contact" component={Contact} />
                  <Route exact path="/service" component={Service} />
                  <Route
                     exact
                     path="/service/softwareDevelopment"
                     component={SoftwareDevelopment}
                  />
                  <Route
                     exact
                     path="/service/softwareTesting"
                     component={SoftwareTesting}
                  />
                  <Route
                     exact
                     path="/service/salesManagement"
                     component={SalesManagement}
                  />
                  <Route
                     exact
                     path="/service/CustomerSupport"
                     component={CustomerSupport}
                  />
                  <Route exact path="/service/bigdata" component={BigData} />
                  <Route
                     exact
                     path="/service/cloudservice"
                     component={CloudServiceSection}
                  />
                  <Route
                     exact
                     path="/careers/department/:departmentTitle/:country"
                     component={Department}
                  />
                  <Route
                     exact
                     path="/careers/department/:department/:country/:jobid"
                     component={JobDescription}
                  />
                  <Route
                     path="*"
                     component={() => <Page404 msg="Opps! Page not Found." />}
                  />
               </Switch>
               <Footer />
            </Suspense>
         </Router>
      </>
   );
};

export default App;
